.installationContainer {
  width: 100%;
  height: 100%;
  min-height: 500px;
  padding: 0px 20px 0 20px;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: minmax(220px, 1fr) 220px 1fr 1fr;
  grid-template-rows: 1% 15% 45% 25% 4%;
  grid-template-areas:
    "info-top top1 top2 top3"
    "form date widget1 widget3"
    "form graph graph widget4"
    "form graph graph widget5"
    "foot1 foot2 foot3 foot4";
}
.installationForm {
  grid-area: form;
  height: max(100%);
  min-height: 400px;

  color: var(--secundary);
  background-color: var(--dark_bg);
}
.container_graph {
  grid-area: graph;
  width: 100%;
  height: 100%;
  background-color: var(--dark_bg);
}
.container_date {
  grid-area: date;
  width: 100%;
  height: 100%;
  background-color: var(--dark_bg);
}
.container_widgets {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: widget1;
  width: 100%;
  height: 100%;
  background-color: var(--dark_bg);
}

/* Tablet view */
@media (max-width: 768px) {
  .installationContainer {
    grid-template-columns: minmax(220px, 1fr) max(130px) 1fr auto;
    grid-template-rows: 1% 15% 45% 25% 4%;
    grid-template-areas:
      "info-top top1 top2 top3"
      "form date widget1 widget3"
      "form graph graph widget4"
      "form graph graph widget5"
      "foot1 foot2 foot3 foot4";
  }
}

/* Mobile view */
@media (max-width: 540px) {
  .installationContainer {
    padding-inline: 5px;
    grid-template-columns: auto;
    grid-template-rows: 1vh 70vh 13vh 10vh 50vh;
    grid-template-areas:
      "info-top"
      "form"
      "date"
      "widget1"
      "graph";
  }
}

.container {
  display: inline-flex;
  align-items: center;
  position: relative;
}
.star {
  width: 14px;
  height: 100%;
  margin-right: 2px;
  display: flex;
  color: #f8d448;
}
.star:last-of-type {
  margin-right: 0;
}

.overlay {
  background-color: #043542;
  opacity: 0.9;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  @supports (mix-blend-mode: color) {
    mix-blend-mode: color;
    opacity: unset;
  }
}

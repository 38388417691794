@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");

:root {
  --primary: #e4b46c;
  --secundary: #be9557;
  --warning: #f8ebc6;
  --danger: #eb3f27;
  --success: #75fa83;
  --info: #aaaaaa;
  --light: #fdfdfd;
  --dark_bg: #043542;
  --dark: #181717;
  --skyblue: #4b537c;
  --dark_skyblue: #25396b;
  --footer: #5f4345;
}

* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  margin: 0;
}

body {
  margin: 0;
  min-height: 100vh;
}
header {
  width: 100%;
  height: 48px;
  position: fixed;
  top: 0;
  z-index: 2;
}

main {
  margin-top: 48px;
  width: 100%;
  height: calc(100vh - 96px);
  min-height: 448px;
  background-image: url("./static/img/lighthouse.png");
  background-size: cover;
  z-index: 1;
}

/****************************** Button styling*********************/
/*General button properties*/
.btn {
  font-weight: 400;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.btn:hover {
  /*  background-color: var(--secundary);*/
  opacity: 0.6;
}
.btn:active {
  background-color: var(--secundary);
  transform: scale(0.9);
}

/*button sizes*/
.btn-small {
  height: 28px;
  width: 70px;
  border-radius: 6px;
}
.btn-medium {
  height: 56px;
  width: 140px;
  border-radius: 10px;
}
.btn-wide {
  height: 28px;
  width: 100%;
  border-radius: 6px;
}

/*button styles*/
.btn-primary-solid {
  background-color: var(--primary);
  color: var(--light);
  border: none;
}
.btn-secundary-solid {
  background-color: var(--secundary);
  color: var(--light);
  border: none;
}
.btn-warning-solid {
  background-color: var(--warning);
  color: var(--dark);
  border: none;
}
.btn-danger-solid {
  background-color: var(--danger);
  color: var(--light);
  border: none;
}
.btn-info-solid {
  background-color: var(--info);
  color: var(--light);
  border: none;
}
.btn-dark-solid {
  background-color: var(--dark);
  color: var(--light);
  border: none;
}
.btn-light-solid {
  background-color: var(--light);
  color: var(--dark);
  border: none;
}

.btn-primary-outline {
  background-color: transparent;
  color: var(--primary);
  border: 2px solid var(--primary);
}
.btn-secundary-outline {
  background-color: transparent;
  color: var(--secundary);
  border: 2px solid var(--secundary);
}
.btn-success-outline {
  background-color: transparent;
  color: var(--success);
  border: 2px solid var(--success);
}
.btn-warning-outline {
  background-color: transparent;
  color: var(--warning);
  border: 2px solid var(--warning);
}
.btn-danger-outline {
  background-color: transparent;
  color: var(--danger);
  border: 2px solid var(--danger);
}
.btn-info-outline {
  background-color: transparent;
  color: var(--info);
  border: 2px solid var(--info);
}
.btn-dark-outline {
  background-color: transparent;
  color: var(--dark);
  border: 2px solid var(--dark);
}
.btn-light-outline {
  background-color: transparent;
  color: var(--light);
  border: 2px solid var(--light);
}
/****************************** End Button styling*********************/

@media (max-width: 540px) {
  main {
    height: auto;
  }
}

.container {
  height: 100vh;
}
.signInPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: min(350px, 95%);
  height: 400px;
  margin-top: 0px;
  margin-inline: auto;
  padding: 20px 20px;
  background-color: #f7f7f7;
  border-radius: 25px;
}
h3 {
  display: block;
  margin-top: 20px;
  text-align: center;
  color: #b4b4b4;
}
.providerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 70%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.starcontainer {
  display: flex;
  height: 80%;
  width: 100%;
}
.name {
  height: 20%;
  font-size: 0.7rem;
  color: var(--primary);
}

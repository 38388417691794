.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 44px;
  width: 200px;
}

.entry_box {
  display: flex;
  width: 180px;
  height: 28px;
}
.entry {
  height: 28px;
  width: 180px;
  display: flex;
  align-items: center;
  border: 2px solid var(--info);
  border-radius: 5px;
}
.value {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60%;
  font-size: 0.9rem;
}
.dimension {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 2px;
  font-weight: bold;
  font-size: 0.7rem;
  border-left: 2px solid var(--info);
}
.info {
  margin: auto auto 1px 3px;
  color: var(--secondary);
}

.container {
  display: flex;
  width: 184px;
  height: 28px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
}

.entry_container {
  display: flex;
  justify-items: flex-end;
  align-items: center;
  width: 152px;
  height: 28px;
}

.entry {
  height: 28px;
  width: 128px;
  display: flex;
  align-items: center;
  border: 2px solid var(--info);
  border-radius: 5px;
}
.value {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 96px;
  font-size: 0.9rem;
}
.dimension {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 32px;
  padding-left: 2px;
  font-weight: bold;
  font-size: 0.7rem;
  border-left: 2px solid var(--info);
}
.info {
  display: flex;
  justify-content: center;
  width: 24px;
  color: var(--secondary);
}

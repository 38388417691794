.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.value {
  display: flex;
  align-items: center;
  height: 80%;
  font-size: 1rem;
  color: var(--primary);
}
.name {
  height: 20%;
  font-size: 0.7rem;
  color: var(--primary);
}
.img {
  width: 100%;
  height: 80%;
}

.userContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 440px;
  height: 250px;
  margin: 50px auto;
}
.userCard {
  margin-top: 30px;
  color: var(--skyblue);
  background-color: var(--secundary);
}
.userCard hr {
  border: 1px solid var(--skyblue);
}
.userContainer h4 {
  text-align: center;
}
.userInfo {
  height: 70%;
}

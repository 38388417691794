.coordinates_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  height: 90px;
}
.title {
  display: flex;
  padding-left: 4px;
  height: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.date_label {
  color: var(--primary);
}

@media screen and (max-width: 768px) {
  .date_label {
    text-align: left;
  }
  .date {
    flex-direction: column;
  }
  .date input {
    max-width: 100px;
  }
}

.container {
  height: 100%;
  padding-top: 8%;
}
.welcomeContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 5% auto 0 auto;
  padding-top: 20px;
  width: min(500px, 90%);
  height: 200px;
  color: white;
  text-align: center;
}
.motto {
  font-size: 2.5rem;
}
.welcomeMessage {
  font-weight: 200;
}

.welcome {
  width: 100%;
  padding-top: 50px;
  padding-left: 50px;
  color: var(--secundary);
}
.remark {
  font-size: 0.7rem;
  font-style: italic;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.title {
  color: var(--light);
  font-size: 1rem;
  height: 20%;
}
.widgets {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80%;
}
.name {
  color: var(--primary);
}
.ratingwidget {
  display: flex;
  flex-direction: column;
}

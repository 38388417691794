a {
  text-decoration: none;
}

.login {
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
}
.login:hover {
  opacity: 60%;
  cursor: pointer;
}
.login:active {
  transform: scale(0.95);
}
.boxLogo {
  display: flex;
  justify-content: center;
  width: 50px;
  height: 100%;
  background-color: #fff;
}
.boxLogo img {
  width: 50%;
  margin: auto;
}

.boxTxt {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
h5 {
  width: 100%;
  text-align: center;
}

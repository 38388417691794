* {
  margin: 0;
}

.container {
  display: flex;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  background-color: var(--dark_skyblue);
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  width: 150px;
}
.logo img {
  width: 25px;
}
.logo_txt {
  margin-left: 10px;
  font-family: Times;
  color: var(--primary);
}

.navbar {
  display: flex;
  justify-content: space-between;
}

.nav_links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}
.nav_links a {
  color: var(--light);
  margin-inline: 10px;
  font-size: 0.9rem;
  color: var(--primary);
}

.mobile_btn {
  display: none;
  padding-top: 6px;
  padding-right: 6px;
  color: var(--primary);
}

@media screen and (max-width: 768px) {
  .logo {
    margin-left: 10px;
  }
  .mobile_btn {
    display: block;
  }
  .navbar {
    flex-direction: column;
    position: fixed;
    width: 100%;
    top: 48px;
    bottom: 0;
    left: -100%;
    right: 0;
    background-color: var(--secundary);
    z-index: 2147483647;
  }
  .active {
    left: 0;
  }
  .nav_links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .nav_links a {
    width: 100%;
    padding: 10px 0;
    font-size: 1.5rem;
    text-align: center;
    border-bottom: 1px solid var(--skyblue);
    color: var(--skyblue);
  }
}

.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 48px;
  width: 100%;
  font-size: 0.8rem;
  color: var(--secundary);
  background-color: var(--dark_skyblue);
}
.author {
  width: 32%;
}
.logos {
  width: 32%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}
.logo {
  margin-right: 16px;
  height: 100%;
  text-align: center;
}

.logo a {
  width: 100%;
  height: 100%;
}

.logo a img {
  height: 100%;
}

.logo svg {
  fill: var(--primary);
  height: 100%;
  width: 32px;
}
.githublogo {
  padding: 8px 0;
}

.opensourcelogo {
  display: flex;
  align-items: center;
  padding: 8px 0;
}
.opensourcelogo img {
  height: 100%;
}
.opensourcelogo p {
  padding-left: 4px;
}
@media (max-width: 768px) {
  .container {
    justify-content: space-around;
  }
  .author {
    width: 60%;
  }
}

@media (max-width: 400px) {
  .container {
    font-size: 0.7rem;
    justify-content: space-around;
  }
}

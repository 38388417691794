.container {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
}
.tab_headings {
  display: flex;
}

.tab_name {
  flex: 1;
  padding: 5px 6px;
  border: 2px solid var(--primary);
  text-align: center;
  font-size: 0.7rem;
  color: var(--primary);
}

.active_tab {
  color: white;
  background-color: var(--primary);
}

.tab_name:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.tab_name:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.tab_name:not(:first-child) {
  border-left: none;
}
.tab_content {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 900px) {
  .container span {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 660px) {
  .container span {
    font-size: 0.8rem;
  }
}

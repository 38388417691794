.lineseparator h6 {
  display: flex;
  flex-direction: row;
  width: 90%;
}

.lineseparator h6:before,
.lineseparator h6:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}

.lineseparator h6:before {
  margin-right: 10px;
}

.lineseparator h6:after {
  margin-left: 10px;
}

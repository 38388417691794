#container {
  width: 100%;
  height: max(120px, 100%);
  margin-top: 14px;
  border-radius: 5px;
}
.map_container {
  position: relative;
  width: 100%;
  height: max(120px, 100%);
}
